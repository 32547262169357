import { Link, useLocation } from "react-router-dom";
import { Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { isUATorStaging } from "@/helpers/urlHelpers";
import { DriverProfile } from "@/redux/slices/driver/types";
import { clsx } from "@/utils";
import { ArchivedStatuses } from "../MyDrivers/types";

export const DriverNavigation = ({ driver }: { driver: DriverProfile }) => {
  const { pathname } = useLocation();

  const showNav = isUATorStaging || import.meta.env.DEV;
  const isArchived = ArchivedStatuses.includes(driver.status);

  return (
    <div className="flex w-[280px] shrink-0 flex-col gap-1.5 p-2.5">
      <Link to="../profile">
        <Typography
          className={clsx(
            "w-full rounded-lg p-2.5 font-medium",
            pathname.includes("profile") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          Driver Profile
        </Typography>
      </Link>
      {!isArchived && (
        <Link to="../vehicles">
          <Typography
            className={clsx(
              "w-full rounded-lg p-2.5 font-medium",
              pathname.includes("vehicles") ? "bg-primary-light text-primary" : "text-neutral-black"
            )}
          >
            Owned & Assigned Vehicles
          </Typography>
        </Link>
      )}
      <Link to="../job-history">
        <Typography
          className={clsx(
            "w-full rounded-lg p-2.5 font-medium",
            pathname.includes("job-history") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          Job History
        </Typography>
      </Link>
      {showNav && (
        <>
          {!isArchived && (
            <Link to="../payout-history">
              <Typography
                className={clsx(
                  "w-full rounded-lg p-2.5 font-medium",
                  pathname.includes("payout-history") ? "bg-primary-light text-primary" : "text-neutral-black"
                )}
              >
                Payout History
              </Typography>
            </Link>
          )}
          <Link to="../admin">
            <Typography
              className={clsx(
                "w-full rounded-lg p-2.5 font-medium",
                pathname.includes("admin") ? "bg-primary-light text-primary" : "text-neutral-black"
              )}
            >
              Admin
            </Typography>
          </Link>
          {!isArchived && (
            <Link to="../history-logs">
              <Typography
                className={clsx(
                  "w-full rounded-lg p-2.5 font-medium",
                  pathname.includes("history-logs") ? "bg-primary-light text-primary" : "text-neutral-black"
                )}
              >
                History Logs
              </Typography>
            </Link>
          )}
        </>
      )}

      <a href={`${getPhpHostUrl()}/driver/${driver.uuid}/profile/`} target="_blank" rel="noreferrer">
        <Typography className={clsx("neutral-black w-full rounded-lg p-2.5 font-medium")}>Legacy Profile</Typography>
      </a>
    </div>
  );
};
